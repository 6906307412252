.slick-dots {
  bottom: -30px;

  li {
    margin: 0 3px;

    button {
      display: none;

      border-radius: 50%;

      &::before {
        width: 100%;
        height: 100%;

        content: "";
        transition: 0.1s ease-in-out;

        opacity: 1;
        border: 2px solid $px-theme;
        border-radius: 50%;

        font-size: 0;
      }

      &::after {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        content: "";
        transition: 0.1s ease-in-out;
        transform: scale(0);

        border-radius: inherit;
        background: $px-theme;
      }
    }

    &.slick-active {
      button {
        &::after {
          transform: scale(0.6);
        }


      }
    }
  }
}


.testimonial-wrapper,
.preview-inner {
  margin: 10px;
  padding: 10px;


  .slick-list {
    margin: 0 20px;
  }
}

.slick-arrow {
  scale: 2;

  display: inline;


}

