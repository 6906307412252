.ReactModal__Body--open {
  .ReactModal__Content--after-open {
    .box_inner {
      .article .article-title h2 {
        color: #0b0b13;
      }

      .article .article-content h1,
      .article .article-content h2,
      .article .article-content h3,
      .article .article-content h4,
      .article .article-content h5,
      .article .article-content h6 {
        color: #0b0b13;
      }

      .px-btn.px-btn-white {
        color: $px-theme;
        border-color: #0b0b13;
        background: $px-theme;

        &:hover {
          color: #0b0b13;
          background: transparent;
        }
      }
    }
  }
}

.theme-dark {
  .resume-row .rb-left label {
    color: #fff;
  }

  .header-left {
    border-right: 1px solid rgb(255 255 255 / 10%);

    .nav-menu li + li {
      border-top-color: rgb(255 255 255 / 10%);
    }
  }

  p,
  span,
  .meta {
    color: rgb(255 255 255 / 80%);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .biography-info .info-list label {
    color: $px-white !important;
  }

  .feature-box-01,
  .feature-box-02,
  .testimonial-01 {
    background: #010101;
  }

  .separated {
    filter: invert(1);
  }

  .home-banner {
    .type-box {
      .desc {
        color: rgb(255 255 255 / 80%);
      }

      .lead {
        color: $px-theme;
      }

      h6 {
        color: #c8c8cb;
      }
    }
  }

  .contact-form {
    color: rgb(255 255 255 / 73%);
    border-color: rgb(255 255 255 / 30%);
    background: #0e0e17;
  }

  .px-btn.px-btn-white {
    color: #0b0b13;
    border-color: #fff;
    background: #fff;

    &:hover {
      color: #fff;
      border-color: $px-theme;
      background: transparent;
    }
  }

  .product-list .filter li.react-tabs__tab {
    color: #fff;
  }
}
