.main-left {
  @include up-sm {
    padding-left: 118px;
  }
}

.header-social {
  padding-top: 12px;
  padding-left: 3%;

  @include down-sm {
    padding-left: 2%;
  }

  @include down-xsm {
    padding-left: 1%;
  }
}

.social {
  display: inline-block;

  padding: 10px 5px;


  font-size: 30px;

}

.kit-med {
  display: inline-block;

  padding: 10px 20px;

  background-color: $px-theme;
}

.header-left {
  position: fixed;
  top: 0;
  left: 0;

  width: 118px;
  height: 100vh;

  border-right: 1px solid rgba($px-dark, 0.1);
  background: $px-blue;

  .scroll-bar {
    height: 100%;

    @include down-sm {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .hl-top {
    a {
      display: inline-block;

      width: 110px;
      height: 120px;

      text-align: center;
      vertical-align: top;
      text-decoration: none;

      font-size: 40px;
      font-weight: 600;
      line-height: 80px;
    }
  }

  .hl-logo {
    margin: 6px;
  }

  .nav-menu {
    padding-top: 45px;
    padding-bottom: 45px;

    li {
      width: 100%;

      + li {
        border-top: 1px solid rgba($px-white, 0.1);

        .theme-light & {
          border-top: 1px solid rgba($px-dark, 0.1);
        }
      }

      .nav-link {
        margin: 0;
        padding: 10px 0;

        text-align: center;
        text-decoration: none;

        color: $px-white;

        font-size: 27px;

        i {
          width: 40px;
          height: 40px;

          font-size: 25px;
          line-height: 40px;
        }

        @include transition(ease all 0.35s);
      }

      &.active {
        .nav-link,
        span {
          color: $px-theme;
        }
      }

      @include up-sm {
        &:hover {
          color: $px-theme;
        }
      }
    }
  }

  &.header-menu-style-two {
    .nav-menu li {
      .nav-link {
        position: relative;

        display: flex;
        align-items: center;
        flex-direction: column;

        padding: 13px 0 10px;

        &::after {
          position: absolute;
          top: auto;
          bottom: 0;
          left: 0;

          width: 2px;
          height: 0;

          content: "";
          transition: 0.3s;

          background: $px-theme;
        }

        .item {
          display: block;

          padding-top: 3px;

          font-size: 15px;
        }
      }

      &.active {
        .nav-link {
          font-weight: normal;

          &::after {
            top: 0;
            bottom: auto;

            height: 100%;
          }
        }
      }
    }
  }

  .social-icons {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    display: none;

    width: 80px;

    a {
      margin-right: 17px;

      color: $px-white;

      font-size: 14px;

      &:hover {
        color: $px-theme;
      }
    }
  }
}

.tooltip {
  font-size: 12px;
}

.mob-header {
  position: fixed;
  z-index: 11;
  top: 20px;
  right: 20px;
  bottom: 20px;

  display: none;

  > .d-flex {
    align-items: center;
  }

  .navbar-brand {
    padding: 0;
  }

  .logo-text {
    color: $px-white;

    font-size: 25px;
    font-weight: 600;
    line-height: 1;

    .theme-light & {
      color: $px-white;
    }
  }

  .fixed-header & {
    background: $px-white;
  }
}

.toggler-menu {
  position: relative;

  width: 60px;
  height: 60px;
  margin-left: auto;
  padding: 0;

  cursor: pointer;

  border: none;
  border-radius: 50%;
  background: $px-theme;

  span {
    position: absolute;
    inset: 0;

    width: 25px;
    height: 2px;
    margin: auto;

    transition: 0.3s;

    color: $px-white;
    background: $px-white;
  }
}

@include down-sm {
  .mob-header {
    display: block;
  }

  .header-left {
    z-index: 222;

    transform: translateX(-100%);

    &.menu-open {
      transform: translateX(0);
    }

    @include transition(ease all 0.35s);
  }
}

.toggler-menu span {
  transition: 0.3s;
}

.toggler-menu span:nth-child(1) {
  top: -17px;
}

.toggler-menu span:nth-child(2) {
  top: 2px;
}

.toggler-menu span:nth-child(3) {
  top: auto;
  bottom: 19px;
}

.toggler-menu .active span:nth-child(1) {
  top: 0;

  transform: rotate(45deg);
}

.toggler-menu .active span:nth-child(2) {
  left: 50%;

  width: 0;
}

.toggler-menu .active span:nth-child(3) {
  top: 18px;

  transform: rotate(-45deg);
}
