.slide-both-side {
  .slide-both-side {
    animation: slide-both-sidee 4s infinite both;
    animation: slide-both-side 4s infinite both;
  }

  .slideTop {
    animation: slideTop 4s infinite both;
  }

  // react tooltip
  .__react_component_tooltip {
    display: inline-flex !important;

    padding: 1px 8px !important;

    text-transform: capitalize !important;

    border-radius: 3px !important;

    font-weight: 400 !important;
  }

  @keyframes slide-both-side {
    0% {
      transform: translate(-5px);
      transform: translateY(-5px);
    }

    50% {
      transform: translateY(5px);
    }

    100% {
      transform: translateY(-5px);
    }
  }

  @keyframes slideTop {
    from {
      transform: translateX(-20px);

      opacity: 0;
    }

    to {
      transform: none;

      opacity: 1;
    }
  }

  @keyframes slideLeft {
    from {
      transform: translateX(-20px);

      opacity: 0;
    }

    to {
      transform: none;

      opacity: 1;
    }
  }
}