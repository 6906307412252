@font-face {
	font-family: et-line;
	src:url('fonts/et-line.eot');
	src:url('fonts/et-line.eot?#iefix') format('embedded-opentype'),
		url('fonts/et-line.woff') format('woff'),
		url('fonts/et-line.ttf') format('truetype'),
		url('fonts/et-line.svg#et-line') format('svg');

	font-weight: normal;
	font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]::before {
	display:inline-block;

	content: attr(data-icon);
	text-transform: none;

	font-family: et-line;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Use the following CSS code if you want to have a class per icon */

/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="icon-"] {
*/
.icon-mobile, .icon-laptop, .icon-desktop, .icon-tablet, .icon-phone, .icon-document, .icon-documents, .icon-search, .icon-clipboard, .icon-newspaper, .icon-notebook, .icon-book-open, .icon-browser, .icon-calendar, .icon-presentation, .icon-picture, .icon-pictures, .icon-video, .icon-camera, .icon-printer, .icon-toolbox, .icon-briefcase, .icon-wallet, .icon-gift, .icon-bargraph, .icon-grid, .icon-expand, .icon-focus, .icon-edit, .icon-adjustments, .icon-ribbon, .icon-hourglass, .icon-lock, .icon-megaphone, .icon-shield, .icon-trophy, .icon-flag, .icon-map, .icon-puzzle, .icon-basket, .icon-envelope, .icon-streetsign, .icon-telescope, .icon-gears, .icon-key, .icon-paperclip, .icon-attachment, .icon-pricetags, .icon-lightbulb, .icon-layers, .icon-pencil, .icon-tools, .icon-tools-2, .icon-scissors, .icon-paintbrush, .icon-magnifying-glass, .icon-circle-compass, .icon-linegraph, .icon-mic, .icon-strategy, .icon-beaker, .icon-caution, .icon-recycle, .icon-anchor, .icon-profile-male, .icon-profile-female, .icon-bike, .icon-wine, .icon-hotairballoon, .icon-globe, .icon-genius, .icon-map-pin, .icon-dial, .icon-chat, .icon-heart, .icon-cloud, .icon-upload, .icon-download, .icon-target, .icon-hazardous, .icon-piechart, .icon-speedometer, .icon-global, .icon-compass, .icon-lifesaver, .icon-clock, .icon-aperture, .icon-quote, .icon-scope, .icon-alarmclock, .icon-refresh, .icon-happy, .icon-sad, .icon-facebook, .icon-twitter, .icon-googleplus, .icon-rss, .icon-tumblr, .icon-linkedin, .icon-dribbble {
	display:inline-block;

	text-transform: none;

	font-family: et-line;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-mobile::before {
	content: "\e000";
}

.icon-laptop::before {
	content: "\e001";
}

.icon-desktop::before {
	content: "\e002";
}

.icon-tablet::before {
	content: "\e003";
}

.icon-phone::before {
	content: "\e004";
}

.icon-document::before {
	content: "\e005";
}

.icon-documents::before {
	content: "\e006";
}

.icon-search::before {
	content: "\e007";
}

.icon-clipboard::before {
	content: "\e008";
}

.icon-newspaper::before {
	content: "\e009";
}

.icon-notebook::before {
	content: "\e00a";
}

.icon-book-open::before {
	content: "\e00b";
}

.icon-browser::before {
	content: "\e00c";
}

.icon-calendar::before {
	content: "\e00d";
}

.icon-presentation::before {
	content: "\e00e";
}

.icon-picture::before {
	content: "\e00f";
}

.icon-pictures::before {
	content: "\e010";
}

.icon-video::before {
	content: "\e011";
}

.icon-camera::before {
	content: "\e012";
}

.icon-printer::before {
	content: "\e013";
}

.icon-toolbox::before {
	content: "\e014";
}

.icon-briefcase::before {
	content: "\e015";
}

.icon-wallet::before {
	content: "\e016";
}

.icon-gift::before {
	content: "\e017";
}

.icon-bargraph::before {
	content: "\e018";
}

.icon-grid::before {
	content: "\e019";
}

.icon-expand::before {
	content: "\e01a";
}

.icon-focus::before {
	content: "\e01b";
}

.icon-edit::before {
	content: "\e01c";
}

.icon-adjustments::before {
	content: "\e01d";
}

.icon-ribbon::before {
	content: "\e01e";
}

.icon-hourglass::before {
	content: "\e01f";
}

.icon-lock::before {
	content: "\e020";
}

.icon-megaphone::before {
	content: "\e021";
}

.icon-shield::before {
	content: "\e022";
}

.icon-trophy::before {
	content: "\e023";
}

.icon-flag::before {
	content: "\e024";
}

.icon-map::before {
	content: "\e025";
}

.icon-puzzle::before {
	content: "\e026";
}

.icon-basket::before {
	content: "\e027";
}

.icon-envelope::before {
	content: "\e028";
}

.icon-streetsign::before {
	content: "\e029";
}

.icon-telescope::before {
	content: "\e02a";
}

.icon-gears::before {
	content: "\e02b";
}

.icon-key::before {
	content: "\e02c";
}

.icon-paperclip::before {
	content: "\e02d";
}

.icon-attachment::before {
	content: "\e02e";
}

.icon-pricetags::before {
	content: "\e02f";
}

.icon-lightbulb::before {
	content: "\e030";
}

.icon-layers::before {
	content: "\e031";
}

.icon-pencil::before {
	content: "\e032";
}

.icon-tools::before {
	content: "\e033";
}

.icon-tools-2::before {
	content: "\e034";
}

.icon-scissors::before {
	content: "\e035";
}

.icon-paintbrush::before {
	content: "\e036";
}

.icon-magnifying-glass::before {
	content: "\e037";
}

.icon-circle-compass::before {
	content: "\e038";
}

.icon-linegraph::before {
	content: "\e039";
}

.icon-mic::before {
	content: "\e03a";
}

.icon-strategy::before {
	content: "\e03b";
}

.icon-beaker::before {
	content: "\e03c";
}

.icon-caution::before {
	content: "\e03d";
}

.icon-recycle::before {
	content: "\e03e";
}

.icon-anchor::before {
	content: "\e03f";
}

.icon-profile-male::before {
	content: "\e040";
}

.icon-profile-female::before {
	content: "\e041";
}

.icon-bike::before {
	content: "\e042";
}

.icon-wine::before {
	content: "\e043";
}

.icon-hotairballoon::before {
	content: "\e044";
}

.icon-globe::before {
	content: "\e045";
}

.icon-genius::before {
	content: "\e046";
}

.icon-map-pin::before {
	content: "\e047";
}

.icon-dial::before {
	content: "\e048";
}

.icon-chat::before {
	content: "\e049";
}

.icon-heart::before {
	content: "\e04a";
}

.icon-cloud::before {
	content: "\e04b";
}

.icon-upload::before {
	content: "\e04c";
}

.icon-download::before {
	content: "\e04d";
}

.icon-target::before {
	content: "\e04e";
}

.icon-hazardous::before {
	content: "\e04f";
}

.icon-piechart::before {
	content: "\e050";
}

.icon-speedometer::before {
	content: "\e051";
}

.icon-global::before {
	content: "\e052";
}

.icon-compass::before {
	content: "\e053";
}

.icon-lifesaver::before {
	content: "\e054";
}

.icon-clock::before {
	content: "\e055";
}

.icon-aperture::before {
	content: "\e056";
}

.icon-quote::before {
	content: "\e057";
}

.icon-scope::before {
	content: "\e058";
}

.icon-alarmclock::before {
	content: "\e059";
}

.icon-refresh::before {
	content: "\e05a";
}

.icon-happy::before {
	content: "\e05b";
}

.icon-sad::before {
	content: "\e05c";
}

.icon-facebook::before {
	content: "\e05d";
}

.icon-twitter::before {
	content: "\e05e";
}

.icon-googleplus::before {
	content: "\e05f";
}

.icon-rss::before {
	content: "\e060";
}

.icon-tumblr::before {
	content: "\e061";
}

.icon-linkedin::before {
	content: "\e062";
}

.icon-dribbble::before {
	content: "\e063";
}
