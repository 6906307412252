// Modal css\

.custom-overlay {
  position: fixed;
  z-index: 99;
  inset: 0;

  background-color: rgb(0 0 0 / 75%);
}

.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;

  overflow: initial;

  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  background: #fff;

  .box_inner {
    overflow-y: auto;

    width: 1000px;
    height: 75vh;

    @media (width <= 1600px) {
      width: 1070px;
    }

    @media (width <= 1400px) {
      width: 900px;
    }

    @media (width <= 992px) {
      width: 700px;
    }

    @media (width <= 768px) {
      width: 560px;
    }

    @media (width <= 599px) {
      width: 460px;
    }

    @media (width <= 490px) {
      width: 360px;
    }

    @media (width <= 420px) {
      width: 320px;
    }
  }

  .scrollable {
    padding: 50px;

    @media (width <= 767px) {
      padding: 30px 15px;
    }
  }

  .blog-grid {
    cursor: default;

    .blog-img img {
      transform: scale(1);
    }

    &:hover .blog-img img {
      transform: scale(1);
      filter: grayscale(0);
    }
  }

  .article-comment {
    @media (width <= 767px) {
      padding: 30px 15px;
    }
  }
}

.ReactModal__Overlay {
  transition: all 0.3s ease;

  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.close-modal {
  position: absolute;
  z-index: 4;
  top: -32px;
  right: -50px;

  display: flex;

  width: 45px;
  height: 45px;

  border: none;
  background: transparent;

  img {
    position: absolute;

    width: 45px;
    height: 45px;

    cursor: pointer;
    transition: 0.3s;
    filter: invert(1);

    @media screen and (width <= 575px) {
      width: 30px;
      height: 30px;
    }
  }

  &:hover {
    img {
      filter: invert(0.8);
    }
  }

  @media screen and (width <= 1199px) {
    top: -60px;
    right: 0;
  }

  @media screen and (width <= 575px) {
    top: -37px;
    right: -10px;
  }
}
