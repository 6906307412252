/* Section
--------------------- */
.section {
  position: relative;

  padding: 100px 2%;

  @include down-md {
    padding-left: 2%;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include down-sm {
    padding-left: 2%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.m-15px-tb {
  margin-top: 15px;
  padding-bottom: 15px;
}

/* Title
----------------------------------- */
.title {
  margin-bottom: 35px;

  h3 {
    position: relative;
    z-index: 1;

    display: inline-block;

    margin: 0;

    vertical-align: top;

    color: $px-white;

    font-size: 30px;
    font-weight: 600;


    &::after {
      position: absolute;
      z-index: -1;
      top: -8px;
      left: -15px;

      width: 45px;
      height: 45px;

      content: "";
      animation: slide-both-side 4s infinite both;

      border-radius: 50%;
      background: $px-theme;


      @include down-md {
        top: -4px;
        left: 0;
      }
    }
  }

  @include down-sm {
    margin-bottom: 25px;
  }
}

.separated {
  padding-top: 80px;
  padding-bottom: 80px;

  background-repeat: repeat-x;
  background-position: center;
  background-size: auto 9px;


  @include down-md {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include down-sm {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

/* Home Banner
----------------------------------- */
.home-banner {
  position: relative;

  overflow: hidden;

  &::after {
    position: absolute;
    right: -20vh;
    bottom: -20vh;

    width: 120vh;
    height: 120vh;

    content: "";

    border-radius: 50%;
    background: rgba($px-black, 0.1);


  }

  .container {
    position: relative;
    z-index: 1;
  }

  .hb-top-fixed {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    left: 0;

    padding: 30px 50px 0;

    .hb-info {
      label,
      a {
        margin: 0;

        color: $px-white;

        font-size: 14px;
        font-weight: 400;


        + a {
          margin-left: 40px;
        }
      }

      a {
        transition: 0.3s;

        &:hover {
          color: $px-theme;
        }
      }
    }

    .hb-lang {
      margin-left: auto;

      li {
        + li {
          margin-left: 10px;
        }

        a {
          padding: 5px 8px;

          color: $px-white;

          font-size: 14px;


        }

        &:hover,
        &.active {
          background: $px-theme;


        }
      }
    }

    @include down-sm {
      display: none !important;
    }
  }
}

.hb-me {
  position: absolute;
  top: 0;
  right: 0;

  width: 50vw;
  height: 100%;

  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;

  @media screen and (width <= 1199px) {
    width: 84vw;

    opacity: 0.6;
  }

  @include down-md {
    width: 80vw;

    opacity: 0.6;
  }

  @include down-sm {
    width: 102vw;

    opacity: 0.4;
  }
}

.full-screen {
  min-height: 100vh;

}

.type-box {
  padding-top: 30%;
  padding-bottom: 0;
  padding-left: 3%;

  h6 {
    margin: 0 0 20px;

    color: $px-white;

    font-size: 20px;
    font-weight: 400;


    @include down-sm {
      font-size: 18px;
    }
  }

  h1 {
    margin: 0 0 15px;

    color: $px-white;

    font-size: 65px;
    font-weight: 700;
    line-height: 1;


    @include down-lg {
      font-size: 45px;
    }

    @include down-sm {
      font-size: 29px;
    }
  }

  .lead {
    margin-bottom: 15px;

    color: $px-white;

    font-size: 30px;
    font-weight: 500;


    @include down-sm {
      margin-bottom: 15px;

      font-size: 20px;
    }
  }

  .desc {
    max-width: 450px;

    font-size: 19px;
    line-height: 1.2;
  }

  .btn-bar {
    padding-top: 10px;

    a {
      position: relative;

      padding-left: 0;

      text-decoration: none;

      color: $px-white;

      font-size: 25px;
      font-weight: 400;
      line-height: 1;

      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        width: 0;
        height: 2px;
        margin: auto;

        content: "";

        background: currentcolor;

        @include transition(ease all 0.35s);
      }

      &:hover {
        padding-left: 45px;

        &::after {
          width: 40px;
        }
      }

      @include transition(ease all 0.35s);


      @include down-sm {
        font-size: 20px;
      }
    }
  }

  @include down-sm {
    padding-top: 30%;
    padding-bottom: 0;
    padding-left: 2%;
  }

  @include down-xsm {
    padding-top: 3%;
    padding-bottom: 0;
    padding-left: 1%;
  }
}


.home-banner.home-banner-3 {
  position: relative;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;


  &::after {
    display: none;


  }


  @media screen {
    background-position: 78% 100%;

    &::before {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      content: "";

      background: rgb(17 39 80 / 70%);
    }

    .full-screen {
      min-height: 100vh;
    }

    .type-box {
      h6,
      h1,
      p {
        color: #fff;
      }
    }
  }


}

.loop-text {
  line-height: 1 !important;
}

/* About
----------------------------------- */
.biography {
  .img-in {
    overflow: hidden;

    max-width: 356px;
    max-height: 358px;
    margin: auto;

    border: 5px solid $px-white;
    border-radius: 50%;


  }

  .img {
    position: relative;
  }

  .info {
    padding-top: 40px;

    text-align: center;

    color: $px-white;


    h3 {
      font-size: 30px;
      font-weight: 500;
    }

    p {
      margin: 0;

      font-size: 16px;
    }
  }


  .social-icons {
    position: absolute;
    right: 0;
    bottom: -15px;
    left: 0;

    a {
      position: relative;

      width: 35px;
      height: 35px;
      margin: 0 5px;

      text-align: center;

      color: $px-dark;
      border: 2px solid $px-white;
      border-radius: 50%;
      background: $px-white;

      line-height: 31px;

      i {
        line-height: inherit;
      }

      &:nth-child(1) {
        top: -25px;
      }

      &:nth-child(2) {
        top: -8px;
      }

      &:nth-child(4) {
        top: -8px;
      }

      &:nth-child(5) {
        top: -25px;
      }

      &:hover {
        color: $px-white;
        background: $px-theme;

        @include scale(1.1);
      }

      @include transition(ease all 0.35s);
      @include scale(1);
    }
  }

  @include down-md {
    margin-bottom: 40px;
  }
}

.biography-info {
  .title {
    margin-bottom: 30px;
  }

  .info-list {
    padding-top: 15px;

    ul {
      margin: 0;
      padding: 0;

      list-style: none;
    }

    li {
      padding: 4px 0;
    }

    label {
      margin: 0;
      padding-right: 10px;

      color: $px-white;

      font-weight: 500;


    }
  }
}

/* Feature Box
----------------------------------- */
.feature-box-01 {
  position: relative;
  z-index: 1;

  overflow: hidden;

  padding: 40px;

  border-radius: 10px;
  background: $px-dark;


  &::before {
    position: absolute;
    z-index: -1;
    top: -220px;
    left: -220px;

    width: 200px;
    height: 200px;

    content: "";
    transition: 0.5s ease-in-out;

    border-radius: 0;
    background: $px-dark;
  }

  &::after {
    position: absolute;
    z-index: -2;
    top: -72px;
    left: -40px;

    width: 200px;
    height: 200px;

    content: "";
    transition: 0.5s ease-in-out;

    border-radius: 50%;
    background: $px-theme;
  }

  .icon {
    color: $px-white;

    font-size: 40px;
    line-height: 60px;
  }

  .feature-content {
    padding-top: 50px;
  }

  h5 {
    margin-bottom: 15px;

    transition: 0.5s ease-in-out;

    color: $px-white;


  }

  p {
    margin: 0;

    transition: 0.5s ease-in-out;
  }

}

.feature-box-02 {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  padding: 15px;

  transition: 0.3s;

  border-radius: 10px;
  background: $px-dark;

  &:hover {
    transform: translateY(-5px);
  }


  .icon {
    display: flex;
    overflow: hidden;

    width: 270px;

    border: 1px solid $px-theme;
    border-radius: 10px;
  }

  .media-body {
    padding: 15px;
  }

  h6 {
    margin: 0 0 5px;

    color: $px-white;

    font-size: 18px;


  }

  p {
    margin: 0;

    font-size: 16px;
  }
}

/* testimonial
----------------------------------- */
.testimonial-01 {
  overflow: hidden;

  margin-top: 10px;
  margin-bottom: 25px;
  padding: 10px;

  //border-radius: 10px;
  //background: $px-dark;


  .avatar {
    overflow: hidden;

    width: 350px;
    height: 200px;


    margin: 10px;

    border: 1px solid $px-theme;
    border-radius: 10px;


    @include down-sm {
      width: 115px;
      height: 65px;
      margin: 10px;
      padding: 0;

    }

    @include down-xsm {
      width: 100px;
      height: 60px;
      margin: 10px;
      padding: 0;

    }
  }

  .media-body {
    padding-left: 10px;

    @include down-sm {
      display: flex;
      flex-direction: column;

      padding-left: 15px;
    }
  }

  h6 {
    margin: 10px 0 5px;

    color: $px-white;

    font-size: 26px;

  }

  span {
    font-size: 15px;
  }

  ul {
    margin-top: 20px;
    padding: 5px;

    list-style: square;
  }

  li {
    position: relative;

    color: $px-theme;

    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
}

/* Experience
----------------------------------- */
.resume-box {
  padding-top: 15px;
}

.resume-row {
  padding: 35px;

  border-radius: 15px;
  background: $px-dark;


  + .resume-row {
    margin-top: 30px;
  }

  h6 {
    color: $px-white;

    font-size: 20px;
    font-weight: 500;


  }

  .rb-left {
    min-height: 100%;

    h6 {
      margin-bottom: 5px;
    }

    label {
      font-size: 16px;
      font-style: italic;
    }

    p {
      margin-top: 5px;
      margin-bottom: 8px;

      line-height: normal;
    }

    .rb-time {
      display: inline-block;

      padding: 5px 10px;

      color: $px-white;
      border-radius: 3px;
      background: $px-theme;

      font-size: 12px;
      font-weight: 500;
    }

    @include up-sm {
      border-right: 1px solid rgba($px-white, 0.05);

    }

    @include down-sm {
      margin-bottom: 25px;
    }
  }

  .rb-right {
    p {
      margin-bottom: 0;
    }
  }
}

.skill-lt {
  position: relative;

  padding-left: 80px;

  .count-inner {
    position: absolute;
    top: 8px;
    left: 0;

    color: $px-white;

    font-size: 30px;
    font-weight: 500;
    line-height: 1;


  }

  &:not(:first-child) {
    margin-top: 45px;
  }

  h6 {
    margin: 0 0 10px;

    color: $px-white;

    font-size: 15px;
    font-weight: 400;


  }

  .skill-bar {
    position: relative;

    background: rgba($px-white, 0.2);


    .skill-bar-in {
      position: relative;

      width: 80px;
      height: 10px;

      background: $px-theme;

      @include transition(ease all 0.55s);
    }
  }
}

.aducation-box {
  margin: 0;
  padding: 35px;

  list-style: none;

  border-radius: 10px;
  background: $px-dark;

  p {
    margin: 0;
  }

  h6 {
    margin: 5px 0;

    color: $px-white;


  }

  li {
    + li {
      margin-top: 25px;
    }
  }


  @include down-md {
    margin-bottom: 10px;
  }
}

/* Contact Us
----------------------------------- */
.contact-info {
  h4 {
    color: $px-white;

    font-size: 34px;
    font-weight: 500;


  }

  p {
    font-size: 16px;
  }

  ul {
    margin: 0;
    padding: 10px 0 0;

    list-style: none;
  }

  li {
    position: relative;

    color: $px-white;

    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;


    i {
      width: 30px;

      text-align: center;

      color: $px-theme;

      font-size: 22px;
      font-weight: 600;
    }

    span {
      padding-left: 15px;
    }

    + li {
      padding-top: 20px;
    }
  }

  @include down-md {
    margin-bottom: 20px;
  }
}

.contact-form {
  h4 {
    margin-bottom: 20px;

    font-size: 22px;
    font-weight: 500;

    //.theme-light & {
    //  color: $px-dark;colorcolorcolor
    //}
  }

  .form-control {
    height: calc(2em + 0.75rem + 2px);

    color: $px-white;
    border: 1px solid rgba($px-white, 0.3);
    border-radius: 0;
    background: none;
    box-shadow: none;

    font-size: 14px;

    &.invalid {
      border-color: #dc3545 !important;
    }


  }

  textarea.form-control {
    height: auto;
  }

  @include down-md {
    padding-bottom: 20px;
  }
}

.google-map {
  margin-top: 50px;

  iframe {
    width: 100%;
    height: 350px;

    border: none !important;
  }

  .embed-responsive-21by9 {
    &::before {
      padding-top: 30%;

      @include down-sm {
        padding-top: 55%;
      }
    }
  }


  @include down-sm {
    margin-top: 15px;
  }
}

.fixd-btn {
  display: inline-block;

  padding: 9px 34px;

  transition: 0.3s;

  color: #fff;
  border-radius: 60px;
  background: #bc0609;

  font-weight: 600;

  &:hover {
    transform: translateY(-5px);

    color: #fff;
  }
}

.slider-four {
  .loop-text {
    color: $px-dark !important;

    @media screen and (width <= 1024px) {
      color: #fff !important;
    }
  }

  .mr-4 {
    margin-right: 20px;
  }

  &.bg-normal {
    @media screen and (width <= 1024px) {
      &.home-banner.home-banner-3::before {
        background: #fff;
      }

      .type-box h6,
      .type-box h1,
      .type-box p,
      .loop-text {
        color: $px-dark !important;
      }
    }

    @media screen and (width >= 767px) and (width <= 1024px) {
      .type-box h1 {
        font-size: 48px;
      }
    }
  }

  @media screen and (width <= 1024px) {
    &.home-banner.home-banner-3::before {
      background: rgb(0 0 0 / 53%);
    }

    .mr-4 {
      margin-right: 15px;
    }

    .px-btn {
      padding: 0 20px;
    }
  }
}

.go-to-next {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 30px;
  left: 0;

  width: 35px;
  margin: auto;

  text-align: center;

  a {
    position: relative;

    display: inline-block;

    width: 35px;
    height: 35px;

    animation: down 1.3s linear infinite;

    border-radius: 50%;
    background: #d9832e;

    span {
      position: absolute;
      inset: -6px 0 0;

      width: 10px;
      height: 10px;
      margin: auto;

      transform: rotate(135deg);

      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
    }
  }
}

@keyframes down {
  0% {
    top: 5px;

    opacity: 0;
  }

  30% {
    top: 15px;

    opacity: 1;
  }

  100% {
    top: 25px;

    opacity: 0;
  }
}

// for particles
.frame-layout__particles {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  #tsparticles {
    height: 100%;
  }


}
