.display-3 {
  font-size: 4rem;
  font-weight: 700;

  @media screen and (width <= 1199px) {
    font-size: 3rem;
  }
}

.white-color {
  color: $px-theme;
}

.h4 {
  font-size: 1.5rem;
}

.error-page-wrapper {
  display: flex;
  align-items: center;

  height: calc(100vh - 71px) !important;
  padding: 80px 0;

  background: $px-blue;

  .inner {
    padding-top: 35px;

    img {
      max-width: 80%;
    }

    .display-3 {
      font-size: 60px;

      @media screen and (width <= 991px) {
        font-size: 40px;
      }
    }

    .h4 {
      margin: 15px 0;

      font-size: 19px;
      font-weight: 400;
      line-height: 1.4;
    }

    @media screen and (width <= 1919px) {
      img {
        max-width: 330px;
      }
    }
  }

  .h4 {
    color: $px-white;
  }
}
