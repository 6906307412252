.footer {
  padding: 12px 0;

  background: $px-dark;


  .nav {
    a {
      margin-right: 17px;

      color: $px-white;

      font-size: 35px;


      &:hover {
        color: $px-theme;
      }
    }
  }

  p {
    margin: 0;

    color: $px-white;

    font-size: 14px;


  }
}
