.px-btn {
  position: relative;

  display: inline-block;

  padding: 0 25px;

  cursor: pointer !important;
  transition: 0.4s;
  text-decoration: none !important;
  letter-spacing: 0.3px;

  border: 1px solid transparent;
  border-radius: 4px;

  font-size: 16px;
  font-weight: 500;
  line-height: 42px;


  &.px-btn-white {
    &:hover {
      background: transparent;
    }
  }
}

.btn-wrapper {
  margin-top: 20px;
}
