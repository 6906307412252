// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;

  flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;

  order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number> 
@mixin flex-shrink($shrink) {
  flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width> 
@mixin flex-basis($width) {
  flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around 
@mixin justify-content($justify) {
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch 
@mixin align-content($align) {
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch 
@mixin align-items($align) {
  -ms-flex-align: $align;

  align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch 
@mixin align-self($align) {
  align-self: $align;
}

// generic transform
@mixin transform($transforms) {
  transform: $transforms;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew($skew) {
  @include transform(skew(#{$skew}deg));
}

//transform origin
@mixin transform-origin($origin) {
  transform-origin: $origin;
}


//Transition
@mixin transition($transition) {
  transition: $transition;
}

//Transition Delay
@mixin transition-delay($delay) {
  transition-delay: $delay;
}

// Media Responsive
@mixin up-xsm {
  @media(min-width: $px-media-xsm + 1) {
    @content;
  }
}

@mixin down-xsm {
  @media(max-width: $px-media-xsm) {
    @content;
  }
}

@mixin up-sm {
  @media(min-width: $px-media-sm + 1) {
    @content;
  }
}

@mixin down-sm {
  @media(max-width: $px-media-sm) {
    @content;
  }
}

@mixin up-md {
  @media(min-width: $px-media-md + 1) {
    @content;
  }
}

@mixin down-md {
  @media(max-width: $px-media-md) {
    @content;
  }
}

@mixin up-lg {
  @media(min-width: $px-media-lg + 1) {
    @content;
  }
}

@mixin down-lg {
  @media(max-width: $px-media-lg) {
    @content;
  }
}

// Hover 
@mixin px-hover {
  &:hover {
    @content;
  }
}