/* ---------------------------
 Product Start
------------------------------ */
.product-content {
  &.grid-col-2 {
    .grid-item {
      width: 50%;

      @include down-sm {
        width: 100%;
      }
    }
  }

  &.grid-col-3 {
    .grid-item {
      @include up-md {
        width: 33.3333%;
      }

      @include down-md {
        width: 50%;
      }

      @include down-sm {
        width: 100%;
      }
    }
  }

  &.grid-col-4 {
    .grid-item {
      @include up-md {
        width: 33.3333%;
      }

      @include up-lg {
        width: 25%;
      }

      @include down-md {
        width: 50%;
      }

      @include down-sm {
        width: 100%;
      }
    }
  }

  &.grid-col-5 {
    .grid-item {
      @include up-md {
        width: 33.3333%;
      }

      @include up-lg {
        width: 20%;
      }

      @include down-md {
        width: 50%;
      }

      @include down-sm {
        width: 100%;
      }
    }
  }

  &.grid-gutter-md {
    margin-right: -4px;
    margin-left: -4px;

    .grid-item {
      padding: 8px;
    }
  }

  &.grid-gutter-lg {
    margin-right: -12px;
    margin-left: -12px;

    .grid-item {
      padding: 12px;
    }
  }

  .grid-item {
    float: left;
  }
}

/* Product Filter */
.product-list {
  .filter {
    li {
      position: relative;

      margin: 0 15px;
      padding: 5px 0;

      cursor: pointer;

      color: $px-white;

      font-size: 16px;
      font-weight: 400;
      line-height: normal;

      .theme-light & {
        color: $px-dark;
      }

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;

        width: 0;
        height: 2px;
        margin: auto;

        content: "";

        background: $px-theme;

        @include transition(ease all 0.55s);
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &.active {
        &::after {
          width: 100%;
        }
      }

      @include down-sm {
        margin: 0 8px;

        font-size: 15px;
      }
    }
  }
}

/* Product Style 2 */
.product-box-01 {
  position: relative;

  background: $px-white;

  .product {
    position: relative;

    overflow: hidden;
  }

  img {
    @include transition(all 0.55s);
  }


  .product-icon {
    position: absolute;
    right: 20px;
    bottom: -20px;

    display: flex;
    visibility: hidden;
    align-items: center;
    justify-content: center;

    width: 45px;
    height: 45px;

    transition: all 0.55s;

    opacity: 0;
    color: #fff;
    background: #fff;

    font-size: 23px;
    line-height: 1;

    a {
      display: inline-block;

      width: 40px;
      height: 40px;

      text-align: center;
      vertical-align: top;

      color: $px-white;
      background: $px-dark;

      font-size: 24px;
      line-height: 40px;

      span {
        line-height: inherit;
      }
    }

    @include transition(ease all 0.35s);
  }

  .product-info {
    position: absolute;
    z-index: 1;
    top: 40px;
    right: 20px;
    left: 20px;

    visibility: hidden;

    padding: 20px;

    opacity: 0;

    h5 {
      margin: 0 0 4px;

      color: $px-white;

      font-size: 22px;
      font-weight: 500;

      &:hover {
        letter-spacing: 0.5px;
      }

      a {
        color: $px-white;
      }

      @media screen and (width <= 991px) {
        font-size: 18px;
        line-height: 29px;
      }
    }

    span {
      color: rgba($px-white, 0.8);
    }

    @media screen and (width <= 991px) {
      padding: 0;
    }

    @include transition(ease all 0.35s);
  }

  &:hover {
    .product-info {
      top: 20px;

      visibility: visible;

      opacity: 1;
    }

    .product-icon {
      bottom: 20px;

      visibility: visible;

      opacity: 1;
    }

    .gallery-link {
      &::after {
        visibility: visible;

        opacity: 1;
      }

      img {
        transform: scale(1.02);
      }
    }
  }
}

// product tab
.product-list {
  padding-top: 15px;

  .filter {
    margin-bottom: 10px;
    padding-left: 0;

    li.react-tabs__tab {
      position: relative;

      margin-right: 30px;
      margin-left: 0;
      padding: 5px 0;

      list-style-type: none;

      cursor: pointer;

      color: $px-dark;

      font-size: 16px;
      font-weight: 400;
      line-height: normal;

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;

        width: 0;
        height: 2px;
        margin: auto;

        content: "";

        background: $px-theme;

        @include transition(ease all 0.55s);
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &.react-tabs__tab--selected {
        &::after {
          width: 100%;
        }
      }

      @include down-sm {
        margin-right: 20px;
        margin-bottom: 6px;

        font-size: 15px;
      }
    }
  }
}
